var parent = require('../../es/symbol');
require('../../modules/esnext.symbol.async-dispose');
require('../../modules/esnext.symbol.dispose');
require('../../modules/esnext.symbol.matcher');
require('../../modules/esnext.symbol.metadata');
require('../../modules/esnext.symbol.observable');
// TODO: Remove from `core-js@4`
require('../../modules/esnext.symbol.pattern-match');
// TODO: Remove from `core-js@4`
require('../../modules/esnext.symbol.replace-all');

module.exports = parent;
