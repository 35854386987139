module.exports = {
  'countBy': require('./countBy'),
  'each': require('./each'),
  'eachRight': require('./eachRight'),
  'every': require('./every'),
  'filter': require('./filter'),
  'find': require('./find'),
  'findLast': require('./findLast'),
  'flatMap': require('./flatMap'),
  'flatMapDeep': require('./flatMapDeep'),
  'flatMapDepth': require('./flatMapDepth'),
  'forEach': require('./forEach'),
  'forEachRight': require('./forEachRight'),
  'groupBy': require('./groupBy'),
  'includes': require('./includes'),
  'invokeMap': require('./invokeMap'),
  'keyBy': require('./keyBy'),
  'map': require('./map'),
  'orderBy': require('./orderBy'),
  'partition': require('./partition'),
  'reduce': require('./reduce'),
  'reduceRight': require('./reduceRight'),
  'reject': require('./reject'),
  'sample': require('./sample'),
  'sampleSize': require('./sampleSize'),
  'shuffle': require('./shuffle'),
  'size': require('./size'),
  'some': require('./some'),
  'sortBy': require('./sortBy')
};
