module.exports = {
  'camelCase': require('./camelCase'),
  'capitalize': require('./capitalize'),
  'deburr': require('./deburr'),
  'endsWith': require('./endsWith'),
  'escape': require('./escape'),
  'escapeRegExp': require('./escapeRegExp'),
  'kebabCase': require('./kebabCase'),
  'lowerCase': require('./lowerCase'),
  'lowerFirst': require('./lowerFirst'),
  'pad': require('./pad'),
  'padEnd': require('./padEnd'),
  'padStart': require('./padStart'),
  'parseInt': require('./parseInt'),
  'repeat': require('./repeat'),
  'replace': require('./replace'),
  'snakeCase': require('./snakeCase'),
  'split': require('./split'),
  'startCase': require('./startCase'),
  'startsWith': require('./startsWith'),
  'template': require('./template'),
  'templateSettings': require('./templateSettings'),
  'toLower': require('./toLower'),
  'toUpper': require('./toUpper'),
  'trim': require('./trim'),
  'trimEnd': require('./trimEnd'),
  'trimStart': require('./trimStart'),
  'truncate': require('./truncate'),
  'unescape': require('./unescape'),
  'upperCase': require('./upperCase'),
  'upperFirst': require('./upperFirst'),
  'words': require('./words')
};
